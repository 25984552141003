import {AppState} from '../../../../store/states/app.state';
import {createSelector} from '@ngrx/store';
import {identity} from 'rxjs';


export class AppNavigationSelectors {

  private static select = {
    buttonEnabled: (state: AppState) => state.appNavigation.buttonEnabled,
  };
  
  public static buttonEnabled = createSelector(
    this.select.buttonEnabled,
    identity,
  );

}

import {createAction} from '@ngrx/store';


export class AppNavigationActions {

  private static readonly prefix = '[App Navigation] ';

  public static enableButton = createAction(
    this.prefix + 'Enable Button.',
  );

  public static disableButton = createAction(
    this.prefix + 'Disable Button.',
  );

}

import {createAction, props} from '@ngrx/store';
import {FakturierungsbelegFormComponentMode} from '../../types/fakturierungsbeleg-form-component-mode.type';
import {BelegDTO, ProduktDTO} from '../../openapi/fakturierung-openapi';
import {PositionAnzahlMode} from '../../interfaces/position-anzahl-modes.interface';
import {PositionAnzahl} from '../../interfaces/position-anzahl.interface';
import {
  PositionBetragssummeMode,
} from '../../interfaces/position-betragssumme-mode.interface';
import {PositionBetragssumme} from '../../interfaces/position-betragssumme.interface';
import {NachlaufzeileInput} from '../../modules/components/fakturierungsbelege-form/nachlaufzeilen/nachlaufzeile-list.component';
import {DateSelectingMode} from '@adnova/jf-ng-components';


export class FakturierungsbelegFormActions {

  private static readonly prefix = '[Fakturierungsbeleg Form] ';

  public static reset = createAction(
    this.prefix + 'Reset this context.',
  );

  public static updateFormValidity = createAction(
    this.prefix + 'Update validity of the Beleg.',
  );

  public static updateFakturierungsbelegFormDto = createAction(
    this.prefix + 'Update the selected fakturierungsbeleg data.',
    props<{
      fakturierungsbelegDto: BelegDTO,
    }>(),
  );

  public static updateGesamtBruttoBetrag = createAction(
    this.prefix + 'Updates the gesamtBruttoBetrag.',
    props<{
      gesamtBruttoBetrag: number,
    }>(),
  );

  public static changeLeistungsempfaengerMode = createAction(
    this.prefix + 'Change Leistungsempfaenger Mode.',
    props<{
      mode: FakturierungsbelegFormComponentMode,
    }>(),
  );

  public static setLeistungsempfaenger = createAction(
    this.prefix + 'Set Leistungsempfänger',
    props<{
      kundeId?: string,
    }>(),
  );

  public static changeVorlaufzeileMode = createAction(
    this.prefix + 'Change Vorlaufzeile Mode.',
    props<{
      mode: FakturierungsbelegFormComponentMode,
    }>(),
  );

  public static setVorlaufzeileText = createAction(
    this.prefix + 'Set Vorlaufzeile Text.',
    props<{
      text: string,
    }>(),
  );

  public static addNachlaufzeile = createAction(
    this.prefix + 'Add Nachlaufzeile',
    props<{
      nachlaufzeile: NachlaufzeileInput,
    }>(),
  );

  public static setNachlaufzeilen = createAction(
    this.prefix + 'Add Nachlaufzeilen',
    props<{
      nachlaufzeilen: NachlaufzeileInput[],
    }>(),
  );

  public static removeNachlaufzeile = createAction(
    this.prefix + 'Remove Nachlaufzeile',
    props<{
      nachlaufzeileId: string,
    }>(),
  );

  public static toggleNachlaufzeilenMode = createAction(
    this.prefix + 'Toggle Nachlaufzeilen Mode',
    props<{
      nachlaufzeileId: string,
    }>(),
  );

  public static selectLeistungsempfaenger = createAction(
    this.prefix + 'Select Leistungsempfänger',
    props<{
      absenderId?: string,
    }>(),
  );

  public static addPosition = createAction(
    this.prefix + 'Add Position to the Fakturierungsbeleg Form.',
    props<{
      produktDto: ProduktDTO,
    }>(),
  );

  public static removePositionByNumber = createAction(
    this.prefix + 'Remove Position',
    props<{
      number: number,
    }>(),
  );

  public static changePositionAnzahlMode = createAction(
    this.prefix + 'Change Position Anzahl Mode.',
    props<{
      positionAnzahlMode: PositionAnzahlMode,
    }>(),
  );

  public static setPositionAnzahl = createAction(
    this.prefix + 'Set Position Anzahl.',
    props<{
      positionAnzahl: PositionAnzahl,
    }>(),
  );

  public static changePositionBetragssummeMode = createAction(
    this.prefix + 'Change Position Betragssumme Mode.',
    props<{
      positionBetragssummeMode: PositionBetragssummeMode,
    }>(),
  );

  public static setPositionBetragssummme = createAction(
    this.prefix + 'Set Position Betragssumme.',
    props<{
      positionBetragssumme: PositionBetragssumme,
    }>(),
  );

  public static changeAddProduktMode = createAction(
    this.prefix + 'Change Add-Produkt Mode.',
    props<{
      mode: FakturierungsbelegFormComponentMode,
    }>(),
  );

  public static changeRechnungsdatumMode = createAction(
    this.prefix + 'Change rechnungsdatum mode.',
    props<{
      mode: FakturierungsbelegFormComponentMode,
    }>(),
  );

  public static changeLeistungsLieferDatumControlMode = createAction(
    this.prefix + 'Change leistungs-liefer-datum control mode.',
    props<{
      mode: FakturierungsbelegFormComponentMode,
    }>(),
  );

  public static changeLeistungsLieferDatumDateMode = createAction(
    this.prefix + 'Change leistungs-liefer-datum date selecting mode.',
    props<{
      mode: DateSelectingMode,
    }>(),
  );

  public static setRechnungsdatum = createAction(
    this.prefix + 'Set Rechnungsdatum.',
    props<{
      rechnungsdatum: string,
    }>(),
  );

  // FIXME: When openAPI is updated/implemented by backend team, implement Reducers and Effects for these actions
  // Potential refactor : use only one single action to update the Store.
  public static setLeistungsLieferDatumSingle = createAction(
    this.prefix + 'Set leistungs-liefer-datum single date.',
    props<{
      leistungsLieferDatum: string,
    }>(),
  );

  public static setLeistungsLieferDatumRange = createAction(
    this.prefix + 'Set leistungs-liefer-datum range date.',
    props<{
      datumVon: string,
      datumBis: string,
    }>(),
  );

  public static getLogo = createAction(
    this.prefix + 'Get logo.',
    props<{ betriebId: string }>(),
  );

  public static getLogoSuccess = createAction(
    this.prefix + 'Get logo successfully.',
    props<{ logoUrl: string | null }>(),
  );

  public static getLogoFailure = createAction(
    this.prefix + 'Get logo failed.',
    props<{ error: any }>(),
  );

  public static saveLogo = createAction(
    this.prefix + 'Save logo.',
    props<{
      betriebId: string,
      logoUrl: string,
    }>(),
  );

  public static saveLogoSuccess = createAction(
    this.prefix + 'Save logo successfully.',
    props<{ logoUrl: string }>(),
  );

  public static saveLogoFailure = createAction(
    this.prefix + 'Save logo failed.',
    props<{ error: any }>(),
  );

  public static deleteLogo = createAction(
    this.prefix + 'Delete logo.',
    props<{ betriebId: string }>(),
  );

  public static deleteLogoSuccess = createAction(
    this.prefix + 'Delete logo successfully.',
  );

  public static deleteLogoFailure = createAction(
    this.prefix + 'Delete logo failed.',
    props<{ error: any }>(),
  );

  public static releaseLogoFromMemory = createAction(
    this.prefix + 'Release logo from browser memory.',
    props<{ logoUrl?: string }>(),
  );

  public static redirectToForm = createAction(
    this.prefix + 'Redirect to form.',
    props<{
      betriebId: string,
      belegId: string,
    }>()
  );

}

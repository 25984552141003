import {createReducer, on} from '@ngrx/store';
import {initialAppNavigationState} from './app-navigation.state.initial';
import {AppNavigationActions} from './app-navigation.actions';


export const appNavigationReducer = createReducer(
  initialAppNavigationState,

  on(
    AppNavigationActions.enableButton,
    (state) => ({
      ...state,
      buttonEnabled: true,
    })
  ),

  on(
    AppNavigationActions.disableButton,
    (state) => ({
      ...state,
      buttonEnabled: false,
    })
  ),
);
